<template>
  <div>
    <div
      v-if="Object.keys(promotion).length === 0"
      class="d-flex justify-content-center mb-1"
    >
      <b-spinner label="Loading..." />
    </div>
    <div v-else>
      <breadcrumbs
        :breadcrumb-items="breadcrumbItems"
      />
      <UserToolbar
        v-if="account && Object.keys(account).length !== 0"
        :account="account"
      />
      <b-form @submit.prevent>
        <validation-observer ref="validationpromotionRules">
          <b-tabs
            pills
          >
            <b-tab active>
              <template #title>
                <feather-icon
                  icon="SettingsIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">{{ $t('Settings') }}</span>
              </template>
              <b-card>
                <div class="head-card">
                  <div class="head-card-top">
                    <h4>{{ $t('Main settings') }}</h4>
                    <feather-icon
                      v-b-tooltip.hover.top="$t('Close')"
                      icon="XIcon"
                      size="18"
                      class="toggle-close cursor-pointer"
                      @click="$router.go(-1)"
                    />
                  </div>
                  <hr>
                </div>
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      :label="$t('promotions.promotiontitle')"
                      label-for="i-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="name"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length == 0 || 'is-invalid'"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="LayoutIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-name"
                            v-model="promotion.title"
                            :placeholder="$t('promotions.promotiontitle')"
                            :state="errors.length > 0 ? false:null"
                            autocomplete="name"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      :label="$t('sites.status')"
                      label-for="i-status"
                    >
                      <b-form-select
                        v-model="status"
                        :options="statusesTranslate"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="12"
                    class="d-flex align-items-center justify-content-end mt-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="mr-0 mr-sm-1"
                      :to="{ path: `/users/${site.user_id}/sites/${site._id}` }"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ $t('form.back') }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="promotionCreate"
                    >
                      <span class="align-middle">{{ $t('form.create') }}</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <b-tab v-if="authUserData.role.includes('admin')">
              <template #title>
                <feather-icon
                  icon="ShieldIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">{{ $t('Administrator') }}</span>
              </template>
              <b-card>
                <div class="head-card">
                  <div class="head-card-top">
                    <h4>{{ $t('sites.adminsettings') }}</h4>
                    <feather-icon
                      v-b-tooltip.hover.top="$t('Close')"
                      icon="XIcon"
                      size="18"
                      class="toggle-close cursor-pointer"
                      @click="$router.go(-1)"
                    />
                  </div>
                  <hr>
                </div>
                <b-row>
                  <b-col md="6">
                    <b-form-group class="mt-1">
                      <b-form-checkbox
                        v-model="is_moderate"
                        value="1"
                      >
                        {{ $t('sites.moderated') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="12"
                    class="d-flex align-items-center justify-content-end mt-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="mr-0 mr-sm-1"
                      :to="{ path: `/users/${site.user_id}/sites/${site._id}` }"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ $t('form.back') }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="promotionCreate"
                    >
                      <span class="align-middle">{{ $t('form.create') }}</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
          </b-tabs>
        </validation-observer>
      </b-form>
    </div>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import options from '@/libs/options'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import {
  BRow,
  BCol,
  BSpinner,
  BTabs,
  BTab,
  BCard,
  BForm,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BFormCheckbox,
  VBTooltip,
} from 'bootstrap-vue'
import {
  required,
  integer,
  min,
} from '@validations'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    Breadcrumbs,
    BRow,
    BCol,
    BSpinner,
    BTabs,
    BTab,
    BCard,
    BForm,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BButton,
    ValidationProvider,
    ValidationObserver,
    UserToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      pageTitle: '',
      min,
      required,
      integer,
      checking: '',
      promotion: {
        title: '',
        status: null,
        user_id: this.$route.params.userId,
        site_id: this.$route.params.siteId,
        is_moderate: false,
      },
      status: 'inactive',
      site: [],
      account: {},
      authUserData: getUserData(),
      is_moderate: [],
      breadcrumbItems: [],
      ...options,
    }
  },
  computed: {
    statusesTranslate() {
      return this.statuses.map(s => {
        const item = {}
        item.value = s.value
        item.text = this.$t(`selects.statuses.${s.value}`)
        return item
      })
    },
  },
  created() {
    this.getUser(this.$route.params.userId)
  },
  methods: {
    async getSiteData(id) {
      const responseData = await useJwt.getSite(id)
      this.site = responseData.data.site || []
      this.pageTitle = this.site.title
      this.setBreadcrumbs()
    },
    async getUser(id) {
      const responseData = await useJwt.getUser(id)
      this.account = responseData.data.account || {}
      this.getSiteData(this.$route.params.siteId)
    },
    setBreadcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$t('page.menu.users'),
          to: '/users/',
        },
        {
          text: this.account.name,
          to: `/users/${this.site.user_id}`,
        },
        {
          text: this.$t('page.menu.sites'),
          to: `/users/${this.site.user_id}/sites/`,
        },
        {
          text: this.site.title,
          to: `/users/${this.site.user_id}/sites/${this.site._id}`,
        },
        {
          text: 'Promotions',
          to: `/users/${this.site.user_id}/sites/${this.site._id}/promotions/`,
        },
        {
          text: this.$t('form.create'),
          active: true,
        },
      ]
    },
    promotionCreate() {
      this.$refs.validationpromotionRules.validate().then(async success => {
        if (success) {
          this.promotion.status = this.status
          this.promotion.is_moderate = this.is_moderate
          await useJwt.createPromotion(this.promotion).then(response => {
            const promoId = response.data.promotion._id
            // console.log(promoId)
            // dashboard-site-promotion-page-create
            if (promoId) {
              this.$router.push(`/users/${this.site.user_id}/sites/${this.site._id}/promotions/${promoId}/promotionpages/create`)
            }
          })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Promotion created',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },
}
</script>
<style scoped>
  .select-append {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .select-append + button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
</style>
